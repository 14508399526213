import { Route, Redirect, Switch, Link } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';
import { Col } from "reactstrap";

const Auth = lazy(() => import('../../www/auth'));
const userOwner = lazy(() => import('../../www/user/userOwner'));
const userRepairer = lazy(() => import('../../www/user/userRepairer'));
const userContact = lazy(() => import('../../www/user/userContact'));
// const certificateNav = lazy(() => import('../../www/certificate'));

const userManagement = lazy(() => import('../../www/userManagement'));
const branch = lazy(() => import('../../www/branch'));
const equipment = lazy(() => import('../../www/equipment'));
const report = lazy(() => import('../../www/report'));
const setting = lazy(() => import('../../www/setting'));
const newUserRepairer = lazy(() => import('../../www/newUserRepairer'));
const holiday = lazy(() => import('../../www/holiday'));
const appointment = lazy(() => import('../../www/appointment'));
const invoices = lazy(() => import('../../www/invoices'));
const elogger = lazy(() => import('../../www/elogger'));
const dlogger = lazy(() => import('../../www/dlogger'));
const transactions = lazy(() => import('../../www/transactions'));
const repairerClasses = lazy(() => import('../../www/class'));
const accountCodes = lazy(() => import('../../www/accountCodes'));
const bankAccounts = lazy(() => import('../../www/bankAccount'));
const stiker = lazy(() => import('../../www/stiker'));
const stikerCode = lazy(() => import('../../www/stikerCode'));

var disablePemilikPembaikMerge = false;

export const fakeAuth = {
    isAuthenticated: localStorage.getItem('user'),
    authenticate(cb) {
        localStorage.setItem('token', global.token)
        localStorage.setItem('user', global.global_id)
        localStorage.setItem('role', global.role)
        localStorage.setItem('position', global.position)
        localStorage.setItem('name', global.name)
        localStorage.setItem('picture', global.picture)
        localStorage.setItem('branch_id', global.branch_id)
        this.isAuthenticated = localStorage.getItem('user')
    },
    signout(cb) {
        localStorage.clear()
        this.isAuthenticated = null
    }
};

export const Page404 = () => (
    <div className="h-100 bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
                <div className="modal-dialog w-100 mx-auto">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="h5 modal-title text-center">
                                <div className="mt-2">
                                    <h2 style={{ fontFamily: 'fantasy' }}>Error 404-Not Found</h2>
                                    <h6>Mohon maaf, halaman yang Anda cari tidak ditemukan.
                                    Kemungkinan halaman dihapus atau Anda salah menuliskan URL.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer clearfix">
                            <div className="float-left">
                                <span>Kembali</span>
                            </div>
                            <div className="float-right">
                                <Link className="mr-2 btn-icon btn-icon-only btn btn-primary" to='/login'><i
                                    className='lnr-home' /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    </div>
);
export const Page401 = () => (
    <div className="h-100 bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
                <div className="modal-dialog w-100 mx-auto">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="h5 modal-title text-center">
                                <div className="mt-2">
                                    <h2 style={{ fontFamily: 'fantasy' }}>Error 401-Unauthorized</h2>
                                    <h6>Mohon maaf, halaman yang Anda cari tidak dapat diproses.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer clearfix">
                            <div className="float-left">
                                <span>Kembali</span>
                            </div>
                            <div className="float-right">
                                <Link className="mr-2 btn-icon btn-icon-only btn btn-primary" to='/login'><i
                                    className='lnr-home' /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    </div>
);
const AppMain = () => {

    return (
        <Fragment>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Switch>
                    <Route path="/401" component={Page401} />
                    <Route exact path="/forgot-password" component={Auth} />
                    <Route exact path="/reset-password/:token" component={Auth} />
                    <Route exact path="/register" component={Auth} />
                    <Route path="/stiker-code" component={stikerCode} />
                    <Route path="/branch" component={branch} />
                    <Route path="/owner" component={userOwner} />
                    <Route path="/repairer" component={userRepairer} />
                    {
                        !disablePemilikPembaikMerge ?
                            <Route path="/contact" component={userContact} />
                            : <Route path="/401" component={Page401} />
                    }
                    <Route path="/contact" component={userContact} />
                    {/*<Route path="/certificate" component={certificateNav}/>*/}
                    <Route path="/Equipment" component={equipment} />
                    <Route path="/report" component={report} />
                    <Route path="/setting" component={setting} />
                    <Route path="/transactions" component={transactions} />
                    <Route path="/account-codes" component={accountCodes} />
                    <Route path="/bank-accounts" component={bankAccounts} />
                    <Route path="/stiker" component={stiker} />
                    {
                        localStorage.getItem('position') === 'HQ' ?
                            <Route path="/new-user-repairer" component={newUserRepairer} />
                            : <Route path="/401" component={Page401} />
                    }
                    {
                        localStorage.getItem('position') === "HQ" || localStorage.getItem('position') === "Manager Cawangan" || localStorage.getItem('position') === "Manager Negeri" || localStorage.getItem('position') === "Staf" || localStorage.getItem('position') === "Kerani" ?
                            <Route path="/appointment" component={appointment} />
                            : <Route path="/401" component={Page401} />
                    }
                    {
                        localStorage.getItem('position') === 'HQ' ?
                            <Route path="/user" component={userManagement} />
                            : <Route path="/401" component={Page401} />
                    }
                    {
                        localStorage.getItem('position') === 'HQ' ?
                            <Route path="/holiday" component={holiday} />
                            : <Route path="/401" component={Page401} />
                    }
                    {
                        localStorage.getItem('position') === 'HQ' && localStorage.getItem('role') === 'Administrator' ?
                            <Route path="/elogger" component={elogger} />
                            : <Route path="/401" component={Page401} />
                    }
                    {
                        localStorage.getItem('position') === 'HQ' && localStorage.getItem('role') === 'Administrator' ?
                            <Route path="/dlogger" component={dlogger} />
                            : <Route path="/401" component={Page401} />
                    }
                    {
                        localStorage.getItem('position') === 'HQ' ?
                            <Route path="/class" component={repairerClasses} />
                            : <Route path="/401" component={Page401} />
                    }
                    <Route path="/invoices" component={invoices} />
                    {
                        localStorage.getItem('position') === 'HQ' ?
                            disablePemilikPembaikMerge ?
                            <Redirect to={'/owner/register'} />:
                            <Redirect to={'/contact/list'} /> : ''
                    }
                    {
                        localStorage.getItem('position') === 'KPDNHEP' ?
                            <Redirect to={'/report/serapan'} /> : ''
                    }
                    {
                        localStorage.getItem('position') === 'Kerani Cawangan' ?
                            <Redirect to={'/report/daily'} /> : ''
                    }
                    {
                        localStorage.getItem('position') === 'Staf' || localStorage.getItem('position') === 'Kerani' ?
                            <Redirect to={'/equipment/list'} /> : ''
                    }
                    {
                        localStorage.getItem('position') === 'Manager Cawangan' ?
                            <Redirect to={'/equipment/list'} /> : ''
                    }
                    {
                        localStorage.getItem('position') === 'Manager Negeri' ?
                            <Redirect to={'/equipment/list'} /> : ''
                    }
                    {
                        localStorage.getItem('user') ?
                            '' :
                            <Route exact path="/login" component={Auth} />
                    }

                    <Route component={Auth} />

                </Switch>

            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/login" />
            )} />
            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;
